<template>
  <v-app>
    <v-app-bar app flat>
      <v-toolbar-title>
        <v-img src="mk-logo.jpg" height="60" width="60"></v-img>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
